<template>
  <v-card
    class="OnHover pa-3"
  >
    <v-toolbar>
      <v-btn-toggle
        v-model="togglePlanType"
        color="deep-purple accent-3"
      >
        <v-btn
          value="FLEXI"
          text
          @click="planClicked('FLEXI')"
        >
          Flexi
        </v-btn>
        <v-btn
          value="SHARABLE"
          text
          @click="planClicked('SHARABLE')"
        >
          Shareable
        </v-btn>
        <v-btn
          value="NON SHARABLE"
          text
          @click="planClicked('NON SHARABLE')"
        >
          Non Shareable
        </v-btn>
      </v-btn-toggle>
      <div class="ma-16"></div>
      <v-btn-toggle
        v-model="toggleDeviceType"
        color="deep-purple accent-3"
      >
        <v-btn
          value="deviceType"
          text
        >
          Device Type
        </v-btn>
        <v-btn
          value="make"
          text
        >
          Device Make
        </v-btn>
        <v-btn
          value="deviceName"
          text
        >
          Device Name
        </v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <v-btn-toggle
        v-if="showFilterText"
        color="deep-purple accent-3"
      >
        <v-btn
          text
          class="v-btn--active"
          @click="removePlanFilter"
        >
          <v-icon
            left
            color="deep-purple accent-3"
          >
            mdi-filter
          </v-icon>
          {{ filterText }}
          <v-icon
            right
            color="deep-purple accent-3"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-btn-toggle>
      <div class="ma-2"></div>
      <v-btn 
        color="primary"
        dark
        class="mb-2 pa-0"
        :before-generate="beforeGenerateExcel"
        :before-finish="beforeFinishExcel"
        @click="handleDownload"
      >
        <v-icon
          small
          class="mr-1"
          right
        >
          mdi-download
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :items-per-page="50"
      :single-select="singleSelect"
      sort-by="planName"
      dense
      @click:row="handleRowClick"
    >
      <template v-slot:item.planName="{ item }">
        <div>
          <v-icon v-if="item.rowIndex == selectedRow">
            mdi-forward
          </v-icon> {{ item.planName }}
        </div>
      </template>
      <!--<template v-slot:item.qty="{ item }">
        <v-chip :color="getColor(item)" dark>{{ item.qty }}</v-chip>
      </template>-->
    </v-data-table>
  </v-card>
</template>

<script>
  import store from '@/store'
  import Utils from '@/api/util'
  import moment from 'moment'
  import { isEmpty } from 'lodash'
  import { findPlanEquipmentSummary } from '@/api/PlanDaoReport'
  import { mapGetters } from 'vuex'
  import { findEquipmentSummary } from '@/api/PouchDaoReport'

  export default {
    name: 'PlansEquipmentDeviceSummaryTable',
    props: {
      planFilter: {
        type: Object,
      },
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
      }),
      ...mapGetters('plan', {
        refreshRequired: 'refreshRequired',
      }),
      filterText: function () {
        if (this.planFilter) {
          return this.planFilter.planName
        } else {
          return ''
        }
      },
      headers: function () {
        const latestMonth = moment(this.customerInfo.latest + '01', 'YYYYMMDD').format('MMM YY')
        return this.baseHeaders.concat([
          { text: 'Devices', value: 'qty' },
          { text: latestMonth + ' Total', value: 'billTotal' },
          { text: '12 Mth Avg', value: 'averageBillTotal' },
          { text: latestMonth + ' Data', value: 'domesticDataUsage' },
          { text: '12 Mth Avg Data', value: 'averageUsageTotal' },
          { text: latestMonth + ' Call Count', value: 'domesticDataCall' },
          { text: '12 Mth Avg Call Count', value: 'averageCallTotal' },
        ])
      },
      excelHeaderRow: function () {
        const excelHeader = {}
        this.headers.forEach(header => {
          excelHeader[header.text] = header.value
        })
        return excelHeader
      },
    },
    watch: {
      planFilter: {
        handler () {
          console.log('refreshRequired changed planFilter to ', this.planFilter)
          this.filterDataFromPlan()
        },
      },
      togglePlanType: {
        handler () {
          console.log('refreshRequired changed togglePlanType to ' + this.togglePlanType)
          this.filterDataFromPlanType()
        },
      },
      toggleDeviceType: {
        handler () {
          console.log('refreshRequired changed toggleDeviceType to ' + this.toggleDeviceType)
          this.filterDataFromTab()
        },
      },
    },
    data () {
      return {
        togglePlanType: 'FLEXI', // 1 for FlEXI
        toggleDeviceType: 'deviceType', // deviceType, make, deviceName
        excelMeta: [
          [
            {
              key: 'charset',
              value: 'utf-8',
            },
          ],
        ],
        excelTitle: 'Equipment Data',
        excelFileName: 'Equipment Data Export',
        excelFileType: 'xlsx',
        chipSelection: 0,
        singleSelect: true,
        selectedRow: -1,
        showFilterText: false,
        baseHeaders: [
          { text: 'Plan Name', value: 'planName' },
          {
            text: 'Device Type',
            align: 'left',
            value: 'deviceType',
          },
          { text: 'Device Make', value: 'make' },
          { text: 'Device Name', value: 'deviceName' },
        ],
        tableData: [],
        downloadLoading: false,
      }
    },
    mounted () {
      this.filterData(this.toggleDeviceType)
    },
    methods: {
      removePlanFilter () {
        this.planFilter = {planName: 'All Plans', planType: 'All'}
        this.showFilterText = false
        this.filterDataRaw(this.toggleDeviceType, null)
        this.$emit('all-plans')
        this.$emit('group-plans', null)
      },
      handleRowClick (value) {
        this.selectedRow = value.rowIndex
        this.$emit('plan-selected', { 
          planType: value.planType,
          planName: value.planName,
          qty: value.qty
        })
        if (this.toggleDeviceType === 'deviceType') {
          this.$emit('filter-equipment', {
            planType: this.togglePlanType,
            planName: value.planName,
            category: this.toggleDeviceType,
            deviceType: value.deviceType,
          })
        }
        if (this.toggleDeviceType === 'make') {
          this.$emit('filter-equipment', {
            planType: this.togglePlanType,
            planName: value.planName,
            category: this.toggleDeviceType,
            deviceType: value.deviceType,
            make: value.make,
          })
        }
        if (this.toggleDeviceType === 'deviceName') {
          this.$emit('filter-equipment', {
            planType: this.togglePlanType,
            planName: value.planName,
            category: this.toggleDeviceType,
            deviceType: value.deviceType,
            make: value.make,
            deviceName: value.deviceName,
          })
        }
      },
      filterDataFromPlan () {
        this.selectedRow = -1
        // now need to filter by selected plan and also show the filter on the screen...
        console.log('filterDataFromPlan planFilter is ', this.planFilter)
        if (isEmpty(this.planFilter) || (this.planFilter.planName === 'All Plans')) {
          this.filterDataRaw(this.toggleDeviceType, null)
        } else {
          this.showFilterText = true
          this.togglePlanType = this.planFilter.planType
          this.filterDataRaw(this.toggleDeviceType, this.planFilter)
        }
      },
      filterDataFromPlanType (planType) {
        this.selectedRow = -1

        if (planType){
          console.log('Plan type clicked, ', planType)

          if (planType === 'FLEXI'){
            this.$emit('group-plans', {planName: 'All Plans', planType: 'All'})
          }
          else if (planType === 'SHARABLE'){
            this.$emit('group-plans', {planName: 'Shareable Plans', planType: 'Shareable'})
          }
          else if (planType === 'NON SHARABLE'){
            this.$emit('group-plans', {planName: 'Non Shareable Plans', planType: 'Non Shareable'})
          }
        }      

        if (isEmpty(this.planFilter) || (this.planFilter.planName === 'All Plans') || (this.planFilter.planName === 'Shareable Plans') || (this.planFilter.planName === 'Non Shareable Plans')) {
          this.filterDataRaw(this.toggleDeviceType, null)
        } else {
          this.filterDataRaw(this.toggleDeviceType, this.planFilter)
        }
      },
      // this is called from the tab headers as well hence single argument
      filterDataFromTab () { // also reset filter to null is showing all records...
        this.selectedRow = -1
        console.log('filterDataFromTab with selectOption ' + this.toggleDeviceType + ' with plan filter ', this.planFilter)
        if (isEmpty(this.planFilter) || (this.planFilter.planName === 'All Plans')) {
          this.filterDataRaw(this.toggleDeviceType, null)
        } else {
          this.filterDataRaw(this.toggleDeviceType, this.planFilter)
        }
        // this.updateFilterText(null)
      },
      filterData (selectOption) { // also reset filter to null is showing all records...
        console.log('filterData with selectOption ' + selectOption + ' with plan filter ', this.planFilter)
        this.filterDataRaw(selectOption, null)
        // this.updateFilterText(null)
      },
      filterDataRaw (selectOption, filterOption) { // selectOption 'deviceName' filterOption {category: 'make', label: 'APPLE'}
        console.log('filterDataRaw selectOption ' + selectOption + ' filterOption is ', filterOption)
        // this.selectedOption = selectOption
        // try to reduce the data by type
        findPlanEquipmentSummary(filterOption).then(result => {
          const reducedResultMap = result.equipmentSummaryList.reduce(
            (map, item) => {
              if (item.planType !== this.togglePlanType) return map
              // console.log('filterData reduce item is ' + item.deviceType)
              let key = item.planName + '-' + item.deviceType
              if (selectOption === 'make') {
                key += '-' + item.make
              }
              if (selectOption === 'deviceName') {
                key += '-' + item.make + '-' + item.deviceName
              }
              const {
                billTotal,
                qty,
                domesticDataUsage,
                domesticDataCall,
                bill12MonthsTotal,
                bill12MonthsCount,
                domesticDataUsage12MonthsTotal,
                domesticDataUsage12MonthsCount,
                domesticDataCall12MonthsTotal,
                domesticDataCall12MonthsCount,
              } = item
              // console.log('filterData reduce item is ' + key + ' billTotal ' + billTotal)
              const prev = map.get(key)
              if (prev) {
                prev.qty += qty
                prev.billTotal += billTotal
                prev.domesticDataUsage += domesticDataUsage
                prev.domesticDataCall += domesticDataCall
                prev.bill12MonthsTotal += bill12MonthsTotal
                prev.bill12MonthsCount += bill12MonthsCount
                prev.domesticDataUsage12MonthsTotal += domesticDataUsage12MonthsTotal
                prev.domesticDataUsage12MonthsCount += domesticDataUsage12MonthsCount
                prev.domesticDataCall12MonthsTotal += domesticDataCall12MonthsTotal
                prev.domesticDataCall12MonthsCount += domesticDataCall12MonthsCount
              } else {
                map.set(key, Object.assign({}, item))
              }
              return map // need to return here so it can be accumulated
            },
            new Map(),
          )
          const monthTotalList = [...reducedResultMap.values()]
          // console.log(monthTotalList)
          this.resetData(monthTotalList)
        })
      },
      resetData (result) {
        // TODO calculate average here and format data
        console.log('resetData called for ' + this.toggleDeviceType)
        let rowIndex = 0
        result.forEach(equipmentSummary => {
          if (equipmentSummary.bill12MonthsCount > 0) {
            equipmentSummary.averageBillTotal = Utils.formatCurrency(equipmentSummary.bill12MonthsTotal / equipmentSummary.bill12MonthsCount)
          } else {
            equipmentSummary.averageBillTotal = Utils.formatCurrency(0.00)
          }
          equipmentSummary.billTotal = Utils.formatCurrency(equipmentSummary.billTotal)

          if (equipmentSummary.domesticDataUsage12MonthsCount > 0) {
            equipmentSummary.averageUsageTotal =
              Utils.readableBytes(equipmentSummary.domesticDataUsage12MonthsTotal / equipmentSummary.domesticDataUsage12MonthsCount)
          } else {
            equipmentSummary.averageUsageTotal = Utils.readableBytes(0)
          }
          if (equipmentSummary.domesticDataCall12MonthsCount > 0) {
            equipmentSummary.averageCallTotal = Math.round(equipmentSummary.domesticDataCall12MonthsTotal / equipmentSummary.domesticDataCall12MonthsCount)
          } else {
            equipmentSummary.averageCallTotal = 0
          }
          equipmentSummary.domesticDataUsage = Utils.readableBytes(equipmentSummary.domesticDataUsage)
          equipmentSummary.rowIndex = rowIndex++
        })
        if (this.toggleDeviceType === 'deviceType') {
          this.baseHeaders = [
            { text: 'Plan Name', value: 'planName' },
            {
              text: 'Device Type',
              align: 'left',
              value: 'deviceType',
            },
          ]
        }
        if (this.toggleDeviceType === 'make') {
          this.baseHeaders = [
            { text: 'Plan Name', value: 'planName' },
            {
              text: 'Device Type',
              align: 'left',
              value: 'deviceType',
            },
            { text: 'Device Make', value: 'make' },
          ]
        }
        if (this.toggleDeviceType === 'deviceName') {
          this.baseHeaders = [
            { text: 'Plan Name', value: 'planName' },
            {
              text: 'Device Type',
              align: 'left',
              value: 'deviceType',
            },
            { text: 'Device Make', value: 'make' },
            { text: 'Device Name', value: 'deviceName' },

          ]
        }
        this.tableData = result
        store.dispatch('plan/refreshRequired', false)
      },
      exportToExcel () {
        return this.tableData
      },
      beforeGenerateExcel () {
        store.dispatch('app/setProgress', true)
      },
      beforeFinishExcel () {
        store.dispatch('app/setProgress', false)
      },
      getColor (sn) {
        return 'green'
      },
      planClicked (planType){
        this.filterDataFromPlanType(planType)
      },
      handleDownload() {
        import('@/vendor/Export2Excel').then(excel => {
          const tHeader = []
          const filterVal = []

          this.headers.forEach((item, index) => {
            tHeader.push(item.text)
            filterVal.push(item.value)
          });
                    
          const list = this.tableData
          const data = list.map( v => filterVal.map(j => v[j] ))  
          excel.export_json_to_excel({
            header: tHeader,
            sheetTitle: 'Plan',
            data,
            filename: this.excelFileName,
            autoWidth: this.autoWidth,
            bookType: this.excelFileType
          })
        })
      }
    },
  }
</script>

<style scoped>
.v-application .deep-purple--text.text--accent-3 {
  color: #ea74cc !important;
  caret-color: #893273 !important;
}
</style>
